import { reactive, toRefs, getCurrentInstance } from "vue";
export default {
  props: {
    listType: {
      type: String,
      default: "picture"
    },
    downloadLabel: {
      type: String,
      default: ""
    },
    downloadUrl: {
      type: String,
      default: "https://tse2-mm.cn.bing.net/th/id/OIP-C.7KW5GT7NQ8yUGlBbCHEm0gHaNK?pid=ImgDet&rs=1"
    },
    downloadName: {
      type: String,
      default: "墨西哥-衣服-裤子-袜子"
    }
  },
  setup(props) {
    const {
      proxy
    } = getCurrentInstance();
    const state = reactive({});
    const download = () => {
      proxy.$downloadFile(props.downloadUrl, props.downloadName);
    };
    return {
      ...toRefs(state),
      download
    };
  }
};