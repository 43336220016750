import waitIcon from "@/assets/images/tc-message-icon/loading.webp";
import errorIcon from "@/assets/images/tc-message-icon/error-icon.svg";
import successIcon from "@/assets/images/tc-message-icon/success-icon.svg";
import { onMounted, ref } from "vue";
export default {
  name: "MessageMain",
  props: {
    text: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "warn"
    }
  },
  setup() {
    const style = {
      loading: {
        icon: waitIcon,
        color: "#fff",
        backgroundColor: "rgba(8, 16, 45, 0.9)"
      },
      error: {
        icon: errorIcon,
        color: "#fff",
        backgroundColor: "rgba(8, 16, 45, 0.9)"
      },
      success: {
        icon: successIcon,
        color: "#fff",
        backgroundColor: "rgba(8, 16, 45, 0.9)"
      }
    };
    const visible = ref(false);
    onMounted(() => {
      visible.value = true;
      setTimeout(() => {}, 2000);
    });
    return {
      style,
      visible
    };
    //       proxy.$TcMsg({
    //   type: "success",
    //   text: "成功"
    // })
  }
};